import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const PageDescription = makeShortcode("PageDescription");
const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Video = makeShortcode("Video");
const Caption = makeShortcode("Caption");
const Accordion = makeShortcode("Accordion");
const AccordionItem = makeShortcode("AccordionItem");
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Title = makeShortcode("Title");
const InlineNotification = makeShortcode("InlineNotification");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <PageDescription mdxType="PageDescription">
      <p>{`This is a demo page to see variations on components before/after headings.`}</p>
    </PageDescription>
    <AnchorLinks mdxType="AnchorLinks">
  <AnchorLink mdxType="AnchorLink">Headers and Paragraphs</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Videos</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Images</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers & Lists</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Accordions</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Tables</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Tabs</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Headers and Code</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Video</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Image</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Lists</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Accordion</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Tables</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Tabs</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Paragraph and Code</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Code and Nested Lists</AnchorLink>
  <AnchorLink mdxType="AnchorLink">Notifications and Lists</AnchorLink>
    </AnchorLinks>
    <h2>{`testing lists`}</h2>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Item with a nested item`}
        <ol parentName="li">
          <li parentName="ol">{`Nested list item`}</li>
          <li parentName="ol">{`Another nested list item`}</li>
        </ol>
      </li>
    </ol>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "poster",
        "title": "poster",
        "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
        "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`This is technically a new ordered list, but we’ve given it the start
attribute of 3`}</li>
      <li parentName="ol">{`To continue from the list above`}</li>
      <li parentName="ol">{`The rest of the numbers don’t matter, but the starting number does`}</li>
    </ol>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <ol {...{
      "start": 6
    }}>
      <li parentName="ol">{`This is also a new ordered list, but we’ve given it the start attribute of 7`}</li>
      <li parentName="ol">{`The rest of the numbers don’t matter, but the starting number does`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}
        <ul parentName="li">
          <li parentName="ul">{`Nested list item`}</li>
          <li parentName="ul">{`Nested list item`}</li>
        </ul>
      </li>
    </ul>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "poster",
        "title": "poster",
        "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
        "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <ul>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
      <li parentName="ul">{`Unordered list can use asterisks`}</li>
    </ul>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <ul>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
    </ul>
    <h2>{`Headers and Paragraphs`}</h2>
    <h2>{`Header 2`}</h2>
    <p>{`I’m a paragraph underneath an h2. Lorem ipsum dolor sit amet, consectetur
adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
id est laborum`}<sup>{`2`}</sup>{`.`}</p>
    <h3>{`Header 3`}</h3>
    <p>{`I’m a paragraph underneath an h3. Lorem ipsum dolor sit amet, consectetur
adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
id est laborum.`}</p>
    <h4>{`Header 4`}</h4>
    <p>{`I’m a paragraph underneath an h4. Lorem ipsum dolor sit amet, consectetur
adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint
occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
id est laborum.`}</p>
    <h2>{`Headers and Videos`}</h2>
    <h2>{`Header 2`}</h2>
    <Video src="/videos/hero-video.mp4" poster="/8006174cef307d9cf1a013bc15b3dd6c/poster.jpg" mdxType="Video">
  <track default src="/videos/vtt/hero-video.vtt" srcLang="en" />
    </Video>
    <h3>{`Header 3`}</h3>
    <Video src="/videos/hero-video.mp4" poster="/8006174cef307d9cf1a013bc15b3dd6c/poster.jpg" mdxType="Video">
  <track default src="/videos/vtt/hero-video.vtt" srcLang="en" />
    </Video>
    <h4>{`Header 4`}</h4>
    <Video src="/videos/hero-video.mp4" poster="/8006174cef307d9cf1a013bc15b3dd6c/poster.jpg" mdxType="Video">
  <track default src="/videos/vtt/hero-video.vtt" srcLang="en" />
    </Video>
    <h2>{`Headers and Images`}</h2>
    <h2>{`Header 2`}</h2>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "poster",
        "title": "poster",
        "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
        "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  This is a regular caption. It will attempt to respond to it’s container
  element appropriately.
    </Caption>
    <h3>{`Header 3`}</h3>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "poster",
        "title": "poster",
        "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
        "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  This is a regular caption. It will attempt to respond to it’s container
  element appropriately.
    </Caption>
    <h4>{`Header 4`}</h4>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "poster",
        "title": "poster",
        "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
        "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <Caption mdxType="Caption">
  This is a regular caption. It will attempt to respond to it’s container
  element appropriately.
    </Caption>
    <h2>{`Headers & Lists`}</h2>
    <h2>{`Header 2`}</h2>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Item with a nested item`}
        <ol parentName="li">
          <li parentName="ol">{`Nested list item`}</li>
        </ol>
      </li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}
        <ul parentName="li">
          <li parentName="ul">{`Nested list item`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
    </ul>
    <h3>{`Header 3`}</h3>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Item with a nested item`}
        <ol parentName="li">
          <li parentName="ol">{`Nested list item`}</li>
        </ol>
      </li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}
        <ul parentName="li">
          <li parentName="ul">{`Nested list item`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
    </ul>
    <h4>{`Header 4`}</h4>
    <ol>
      <li parentName="ol">{`First ordered list item`}</li>
      <li parentName="ol">{`Item with a nested item`}
        <ol parentName="li">
          <li parentName="ol">{`Nested list item`}</li>
        </ol>
      </li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}
        <ul parentName="li">
          <li parentName="ul">{`Nested list item`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
    </ul>
    <h2>{`Headers and Accordions`}</h2>
    <h2>{`Header 2`}</h2>
    <Accordion mdxType="Accordion">
  <AccordionItem title="Title 1" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 2" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 3" mdxType="AccordionItem">Content Section</AccordionItem>
    </Accordion>
    <h3>{`Header 3`}</h3>
    <Accordion mdxType="Accordion">
  <AccordionItem title="Title 1" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 2" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 3" mdxType="AccordionItem">Content Section</AccordionItem>
    </Accordion>
    <h4>{`Header 4`}</h4>
    <Accordion mdxType="Accordion">
  <AccordionItem title="Title 2" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 1" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 3" mdxType="AccordionItem">Content Section</AccordionItem>
    </Accordion>
    <h2>{`Headers and Tables`}</h2>
    <h2>{`Header 2`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Header 3`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <h4>{`Header 4`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Headers and Tabs`}</h2>
    <h2>{`Header 2`}</h2>
    <Tabs mdxType="Tabs">
      <Tab label="Text" mdxType="Tab">
        <p>{`The tab component can be used for a variety of content. From text, to images, to
columns. If you want to have multiple columns, you’ll need to use our `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and
`}<inlineCode parentName="p">{`Column`}</inlineCode>{` helpers as demonstrated in the “Two images” tab.`}</p>
      </Tab>
      <Tab label="Single image" mdxType="Tab">
        <p>{`If your content is full width, you don’t need `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode></p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "poster",
            "title": "poster",
            "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
            "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </Tab>
      <Tab label="Two images" mdxType="Tab">
        <Row mdxType="Row">
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column one`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "poster",
                "title": "poster",
                "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
                "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
                "sizes": "(max-width: 1152px) 100vw, 1152px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>
          </Column>
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column two`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "poster",
                "title": "poster",
                "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
                "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
                "sizes": "(max-width: 1152px) 100vw, 1152px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>
          </Column>
        </Row>
      </Tab>
    </Tabs>
    <h3>{`Header 3`}</h3>
    <Tabs mdxType="Tabs">
      <Tab label="Text" mdxType="Tab">
        <p>{`The tab component can be used for a variety of content. From text, to images, to
columns. If you want to have multiple columns, you’ll need to use our `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and
`}<inlineCode parentName="p">{`Column`}</inlineCode>{` helpers as demonstrated in the “Two images” tab.`}</p>
      </Tab>
      <Tab label="Single image" mdxType="Tab">
        <p>{`If your content is full width, you don’t need `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode></p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "poster",
            "title": "poster",
            "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
            "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </Tab>
      <Tab label="Two images" mdxType="Tab">
        <Row mdxType="Row">
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column one`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "poster",
                "title": "poster",
                "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
                "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
                "sizes": "(max-width: 1152px) 100vw, 1152px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>
          </Column>
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column two`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "poster",
                "title": "poster",
                "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
                "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
                "sizes": "(max-width: 1152px) 100vw, 1152px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>
          </Column>
        </Row>
      </Tab>
    </Tabs>
    <h4>{`Header 4`}</h4>
    <Tabs mdxType="Tabs">
      <Tab label="Text" mdxType="Tab">
        <p>{`The tab component can be used for a variety of content. From text, to images, to
columns. If you want to have multiple columns, you’ll need to use our `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and
`}<inlineCode parentName="p">{`Column`}</inlineCode>{` helpers as demonstrated in the “Two images” tab.`}</p>
      </Tab>
      <Tab label="Single image" mdxType="Tab">
        <p>{`If your content is full width, you don’t need `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode></p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "poster",
            "title": "poster",
            "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
            "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </Tab>
      <Tab label="Two images" mdxType="Tab">
        <Row mdxType="Row">
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column one`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "poster",
                "title": "poster",
                "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
                "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
                "sizes": "(max-width: 1152px) 100vw, 1152px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>
          </Column>
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column two`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "poster",
                "title": "poster",
                "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
                "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
                "sizes": "(max-width: 1152px) 100vw, 1152px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>
          </Column>
        </Row>
      </Tab>
    </Tabs>
    <h2>{`Headers and Code`}</h2>
    <h2>{`Header 2`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Accordion/Accordion.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion",
        "path": "components/Accordion/Accordion.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion"
      }}>{`<Accordion>
  <AccordionItem title="Title 1">Content Section</AccordionItem>
  <AccordionItem title="Title 2">Content Section</AccordionItem>
  <AccordionItem title="Title 3">Content Section</AccordionItem>
</Accordion>
`}</code></pre>
    <h3>{`Header 3`}</h3>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Accordion/Accordion.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion",
        "path": "components/Accordion/Accordion.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion"
      }}>{`<Accordion>
  <AccordionItem title="Title 1">Content Section</AccordionItem>
  <AccordionItem title="Title 2">Content Section</AccordionItem>
  <AccordionItem title="Title 3">Content Section</AccordionItem>
</Accordion>
`}</code></pre>
    <h4>{`Header 4`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Accordion/Accordion.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion",
        "path": "components/Accordion/Accordion.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion"
      }}>{`<Accordion>
  <AccordionItem title="Title 1">Content Section</AccordionItem>
  <AccordionItem title="Title 2">Content Section</AccordionItem>
  <AccordionItem title="Title 3">Content Section</AccordionItem>
</Accordion>
`}</code></pre>
    <h2>{`Paragraph and Video`}</h2>
    <p>{`I’m a paragraph above a video. Lorem ipsum dolor sit amet, consectetur
adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <Video src="/videos/hero-video.mp4" poster="/8006174cef307d9cf1a013bc15b3dd6c/poster.jpg" mdxType="Video">
  <track default src="/videos/vtt/hero-video.vtt" srcLang="en" />
    </Video>
    <p>{`I’m a paragraph below a video. Lorem ipsum dolor sit amet, consectetur
adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and Image`}</h2>
    <p>{`I’m a paragraph above an image. Lorem ipsum dolor sit amet, consectetur
adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "56.25%",
          "position": "relative",
          "bottom": "0",
          "left": "0",
          "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "poster",
        "title": "poster",
        "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
        "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px",
        "style": {
          "width": "100%",
          "height": "100%",
          "margin": "0",
          "verticalAlign": "middle",
          "position": "absolute",
          "top": "0",
          "left": "0"
        },
        "loading": "lazy",
        "decoding": "async"
      }}></img>{`
    `}</span>
    <p>{`I’m a paragraph below an image. Lorem ipsum dolor sit amet, consectetur
adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and Lists`}</h2>
    <p>{`I’m a paragraph above a list. Lorem ipsum dolor sit amet, consectetur adipiscers
elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
commodo consequat. Duis aute irure dolor in`}</p>
    <ol>
      <li parentName="ol">{`First ordered list item. Lorem ipsum dolor sit amet, consectetur adipiscers
elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
aliquip ex ea commodo consequat.`}</li>
      <li parentName="ol">{`Item with a nested item. Lorem ipsum dolor sit amet, consectetur adipiscers
elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
aliquip ex ea commodo consequat.`}
        <ol parentName="li">
          <li parentName="ol">{`Nested list item. Lorem ipsum dolor sit amet, consectetur adipiscers elit,
sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip
ex ea commodo consequat.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`In markdown, the actual numbers don’t matter, just that it’s a number`}</li>
    </ol>
    <ul>
      <li parentName="ul">{`Unordered list can use asterisks`}
        <ul parentName="li">
          <li parentName="ul">{`Nested list item`}</li>
        </ul>
      </li>
      <li parentName="ul">{`Or hyphens to create list items`}</li>
    </ul>
    <p>{`I’m a paragraph below a list. Lorem ipsum dolor sit amet, consectetur adipiscers
elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and Accordion`}</h2>
    <p>{`I’m a paragraph above an Accordion. Lorem ipsum dolor sit amet, consectetur
adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <Accordion mdxType="Accordion">
  <AccordionItem title="Title 1" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 2" mdxType="AccordionItem">Content Section</AccordionItem>
  <AccordionItem title="Title 3" mdxType="AccordionItem">Content Section</AccordionItem>
    </Accordion>
    <p>{`I’m a paragraph below an Accordion. Lorem ipsum dolor sit amet, consectetur
adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and Tables`}</h2>
    <p>{`I’m a paragraph above a table. Lorem ipsum dolor sit amet, consectetur
adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Tables`}</th>
          <th parentName="tr" {...{
            "align": "center"
          }}>{`Are`}</th>
          <th parentName="tr" {...{
            "align": "right"
          }}>{`Cool`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 3 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`right-aligned`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1600`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`col 2 is`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`centered`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$12`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`zebra stripes`}</td>
          <td parentName="tr" {...{
            "align": "center"
          }}>{`are neat`}</td>
          <td parentName="tr" {...{
            "align": "right"
          }}>{`\\$1`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`I’m a paragraph below a table. Lorem ipsum dolor sit amet, consectetur
adipiscers elit, sed do eiusmod tempor incididunt ut labore et dolore magna
aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi
ut aliquip ex ea commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and Tabs`}</h2>
    <p>{`I’m a paragraph above Tabs. Lorem ipsum dolor sit amet, consectetur adipiscers
elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
commodo consequat. Duis aute irure dolor in`}</p>
    <Tabs mdxType="Tabs">
      <Tab label="Text" mdxType="Tab">
        <p>{`The tab component can be used for a variety of content. From text, to images, to
columns. If you want to have multiple columns, you’ll need to use our `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and
`}<inlineCode parentName="p">{`Column`}</inlineCode>{` helpers as demonstrated in the “Two images” tab.`}</p>
      </Tab>
      <Tab label="Single image" mdxType="Tab">
        <p>{`If your content is full width, you don’t need `}<inlineCode parentName="p">{`Row`}</inlineCode>{` and `}<inlineCode parentName="p">{`Column`}</inlineCode></p>
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.25%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "poster",
            "title": "poster",
            "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
            "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span>
      </Tab>
      <Tab label="Two images" mdxType="Tab">
        <Row mdxType="Row">
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column one`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "poster",
                "title": "poster",
                "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
                "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
                "sizes": "(max-width: 1152px) 100vw, 1152px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>
          </Column>
          <Column colSm={6} colLg={6} mdxType="Column">
            <p>{`Column two`}</p>
            <span {...{
              "className": "gatsby-resp-image-wrapper",
              "style": {
                "position": "relative",
                "display": "block",
                "marginLeft": "auto",
                "marginRight": "auto",
                "maxWidth": "1152px"
              }
            }}>{`
      `}<span parentName="span" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "56.25%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMFAf/EABUBAQEAAAAAAAAAAAAAAAAAAAQD/9oADAMBAAIQAxAAAAHXzbizxBgqP//EABsQAAEEAwAAAAAAAAAAAAAAAAABAgMREhQz/9oACAEBAAEFAth5Pggt2IxpL0//xAAXEQADAQAAAAAAAAAAAAAAAAAAAQIR/9oACAEDAQE/AY2kM//EABgRAQADAQAAAAAAAAAAAAAAAAEAAxIx/9oACAECAQE/Abc1okOT/8QAHRAAAgEEAwAAAAAAAAAAAAAAABEhAQIQMRJBcf/aAAgBAQAGPwLoo3yWyd4aku9P/8QAHRAAAgICAwEAAAAAAAAAAAAAAAERITFRQYGRof/aAAgBAQABPyFtUUnlIkJoEz2SNfaRZFn7HZ9Y/9oADAMBAAIAAwAAABAcD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAEXH/2gAIAQMBAT8Qahd7DFL/xAAYEQEAAwEAAAAAAAAAAAAAAAABABExgf/aAAgBAgEBPxABFbnI7DP/xAAdEAEBAAEEAwAAAAAAAAAAAAABEQAhUWFxMUHw/9oACAEBAAE/EFUySgTk1wSmaWNEh23xp6h5Lzng7yXFNjKNV958rfP/2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="span" {...{
                "className": "gatsby-resp-image-image",
                "alt": "poster",
                "title": "poster",
                "src": "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg",
                "srcSet": ["/static/8006174cef307d9cf1a013bc15b3dd6c/69549/poster.jpg 288w", "/static/8006174cef307d9cf1a013bc15b3dd6c/473e3/poster.jpg 576w", "/static/8006174cef307d9cf1a013bc15b3dd6c/2e753/poster.jpg 1152w"],
                "sizes": "(max-width: 1152px) 100vw, 1152px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
    `}</span>
          </Column>
        </Row>
      </Tab>
    </Tabs>
    <p>{`I’m a paragraph below Tabs. Lorem ipsum dolor sit amet, consectetur adipiscers
elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Paragraph and Code`}</h2>
    <p>{`I’m a paragraph above code. Lorem ipsum dolor sit amet, consectetur adipiscers
elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
commodo consequat. Duis aute irure dolor in`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-mdx",
        "metastring": "path=components/Tabs/Tabs.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Tabs",
        "path": "components/Tabs/Tabs.js",
        "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Tabs"
      }}>{`<Tab label="Two images">

<Row>
<Column colSm={6} colLg={6}>

Column one

![](./poster.jpg)

</Column>
<Column colSm={6} colLg={6}>

Column two

![](./poster.jpg)

</Column>
</Row>

</Tab>

// Make sure you close the wrapper component

</Tabs>
`}</code></pre>
    <p>{`I’m a paragraph below code. Lorem ipsum dolor sit amet, consectetur adipiscers
elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
commodo consequat. Duis aute irure dolor in`}</p>
    <h2>{`Code & Nested Lists`}</h2>
    <Title mdxType="Title">Ordered List</Title>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Fist ordered list item`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Item with a nested code block. Perspiciatis cupiditate eveniet reprehenderit.
Dolores eum dolor at officia qui libero adipisci. Consequuntur culpa qui
laudantium iure reiciendis mollitia est eveniet illum. Sunt ut et. Quia earum
dolore dolor.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-mdx",
            "metastring": "path=components/Accordion/Accordion.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion",
            "path": "components/Accordion/Accordion.js",
            "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion"
          }}>{`<Accordion>
  <AccordionItem title="Title 1">Content Section</AccordionItem>
  <AccordionItem title="Title 2">Content Section</AccordionItem>
  <AccordionItem title="Title 3">Content Section</AccordionItem>
</Accordion>
`}</code></pre>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Third ordered list item`}</p>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Item with nested list. Consequuntur et excepturi aperiam labore accusamus.
Fuga itaque ut molestiae quia minus voluptatem iste officiis enim.
Reprehenderit et aperiam. Rerum molestiae delectus iusto provident sint
cupiditate.`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Nested list item with code blcok. Consequuntur et excepturi aperiam labore
accusamus. Fuga itaque ut molestiae quia minus voluptatem iste officiis
enim. Reprehenderit et aperiam. Rerum molestiae delectus iusto provident
sint cupiditate.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-mdx",
                "metastring": "path=components/Accordion/Accordion.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion",
                "path": "components/Accordion/Accordion.js",
                "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion"
              }}>{`<Accordion>
  <AccordionItem title="Title 1">Content Section</AccordionItem>
  <AccordionItem title="Title 2">Content Section</AccordionItem>
  <AccordionItem title="Title 3">Content Section</AccordionItem>
</Accordion>
`}</code></pre>
          </li>
        </ol>
      </li>
    </ol>
    <Title mdxType="Title">Unordered List</Title>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Unorderd list item`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Item with a nested code block. Perspiciatis cupiditate eveniet reprehenderit.
Dolores eum dolor at officia qui libero adipisci. Consequuntur culpa qui
laudantium iure reiciendis mollitia est eveniet illum. Sunt ut et. Quia earum
dolore dolor.`}</p>
        <pre parentName="li"><code parentName="pre" {...{
            "className": "language-mdx",
            "metastring": "path=components/Accordion/Accordion.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion",
            "path": "components/Accordion/Accordion.js",
            "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion"
          }}>{`<Accordion>
  <AccordionItem title="Title 1">Content Section</AccordionItem>
  <AccordionItem title="Title 2">Content Section</AccordionItem>
  <AccordionItem title="Title 3">Content Section</AccordionItem>
</Accordion>
`}</code></pre>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Unorderd list item`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Item with nested list. Consequuntur et excepturi aperiam labore accusamus.
Fuga itaque ut molestiae quia minus voluptatem iste officiis enim.
Reprehenderit et aperiam. Rerum molestiae delectus iusto provident sint
cupiditate.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Nested list item with code blcok. Consequuntur et excepturi aperiam labore
accusamus. Fuga itaque ut molestiae quia minus voluptatem iste officiis
enim. Reprehenderit et aperiam. Rerum molestiae delectus iusto provident
sint cupiditate.`}</p>
            <pre parentName="li"><code parentName="pre" {...{
                "className": "language-mdx",
                "metastring": "path=components/Accordion/Accordion.js src=https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion",
                "path": "components/Accordion/Accordion.js",
                "src": "https://github.com/carbon-design-system/gatsby-theme-carbon/tree/main/packages/gatsby-theme-carbon/src/components/Accordion"
              }}>{`<Accordion>
  <AccordionItem title="Title 1">Content Section</AccordionItem>
  <AccordionItem title="Title 2">Content Section</AccordionItem>
  <AccordionItem title="Title 3">Content Section</AccordionItem>
</Accordion>
`}</code></pre>
          </li>
        </ul>
      </li>
    </ul>
    <h2>{`Notifications and Lists`}</h2>
    <InlineNotification mdxType="InlineNotification">
      <p>{`A basic Notification. Omnis officiis culpa enim harum qui sint soluta qui nam.
Labore minus sunt suscipit reiciendis laudantium nihil dolores quia. Enim quod
ea omnis nihil cum. Similique consequatur illum ea libero quam. Earum quis vel
fugiat dicta sapiente.`}</p>
    </InlineNotification>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`a list item. Omnis officiis culpa enim harum qui sint soluta qui nam. Labore
minus sunt suscipit reiciendis laudantium nihil dolores quia. Enim quod ea
omnis nihil cum. Similique consequatur illum ea libero quam. Earum quis vel
fugiat dicta sapiente.`}</p>
        <InlineNotification mdxType="InlineNotification">
          <p parentName="li">{`A basic Notification.Omnis officiis culpa enim harum qui sint soluta qui nam.
Labore minus sunt suscipit reiciendis laudantium nihil dolores quia. Enim quod
ea omnis nihil cum. Similique consequatur illum ea libero quam. Earum quis vel
fugiat dicta sapiente.`}</p>
        </InlineNotification>
      </li>
      <li parentName="ul">
        <p parentName="li">{`another item. Omnis officiis culpa enim harum qui sint soluta qui nam. Labore
minus sunt suscipit reiciendis laudantium nihil dolores quia. Enim quod ea
omnis nihil cum. Similique consequatur illum ea libero quam. Earum quis vel
fugiat dicta sapiente.`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`with a Notification. Omnis officiis culpa enim harum qui sint soluta qui
nam. Labore minus sunt suscipit reiciendis laudantium nihil dolores quia.
Enim quod ea omnis nihil cum. Similique consequatur illum ea libero quam.
Earum quis vel fugiat dicta sapiente.`}</p>
            <InlineNotification mdxType="InlineNotification">
              <p parentName="li">{`A basic Notification. Omnis officiis culpa enim harum qui sint soluta qui
nam. Labore minus sunt suscipit reiciendis laudantium nihil dolores quia.
Enim quod ea omnis nihil cum. Similique consequatur illum ea libero quam.
Earum quis vel fugiat dicta sapiente.`}</p>
            </InlineNotification>
          </li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      